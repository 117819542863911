  @media (max-width: 992px) {

    .hamburgerMenu {
      display: none !important;
    }
  }

  @media (min-width: 992px) {
    .hamburgerDrawer {
      display: none !important;
    }
  }