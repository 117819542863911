body,
html {
    margin: 0;
    padding: 0;
    /*height: 100%;*/
}


@media (max-width:1000px) {
    .headerLinks {
        display: none;
    }
}

/* @media (min-width:500px) {
    .headerLinks {
        display: none;
    }
} */